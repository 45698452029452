// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-covid-jsx": () => import("./../../../src/pages/covid.jsx" /* webpackChunkName: "component---src-pages-covid-jsx" */),
  "component---src-pages-downloads-jsx": () => import("./../../../src/pages/downloads.jsx" /* webpackChunkName: "component---src-pages-downloads-jsx" */),
  "component---src-pages-facilities-jsx": () => import("./../../../src/pages/facilities.jsx" /* webpackChunkName: "component---src-pages-facilities-jsx" */),
  "component---src-pages-general-meetings-jsx": () => import("./../../../src/pages/general_meetings.jsx" /* webpackChunkName: "component---src-pages-general-meetings-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-investors-relation-jsx": () => import("./../../../src/pages/investors_relation.jsx" /* webpackChunkName: "component---src-pages-investors-relation-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */)
}

